import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Container, Typography } from '@mui/material';
import SpinnerQuotes from '../../../components/SpinnerQuotes';
import ToolsContainerMobile from '../../../components/ToolsContainerMobile';
import { SpinnerContainer } from '../../../styles/Comstyles';
import { theme } from '../../../themes/Theme';
import useAuthorName from '../../author/hooks/useAuthorName';
import useDailyQuotes from '../hooks/useDailyQuotes';
import styled from '@emotion/styled';
import useBgImages from '../../bgimage/hooks/useBgImage';

const ImageContainer = styled('div')(({ theme }) => ({
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: '35rem',
  display: 'flex',
  alignItems: 'center',
}));

export default function DailyQuotesMobile() {
  const [dailyQuotes, pendingDailyQuote] = useDailyQuotes([]);
  const [authorName, pendingAuthorName] = useAuthorName([]);
  const [currentDailyQuote, setCurrentDailyQuote] = useState(null);
  const [textSize, setTextSize] = useState(100);
  const [bgChange, setBgChange] = useState();
  const [textColor, setTextColor] = useState('#FFFFFF');
  const [bgImages] = useBgImages('general');

  const ImageTextContainer = styled('div')(({ theme }) => ({
    color: theme.palette.text.secondary,
    margin: '0.2rem',
    flexGrow: '1',
  }));

  const EnglishQuoteText = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: `${textSize - 20}%`,
    color: textColor,
  }));

  const EnglishAuthorText = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: `${textSize - 30}%`,
    color: textColor,
  }));

  const QuoteText = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: `${textSize}%`,
    color: textColor,
  }));

  const AuthorText = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: `${textSize - 20}%`,
    color: textColor,
  }));

  const QuoteContainer = styled(Typography)(({ theme }) => ({
    margin: '3rem',
  }));
  useEffect(() => {
    if (dailyQuotes.length > 0) {
      setCurrentDailyQuote(dailyQuotes[0]);
    }
  }, [dailyQuotes]);

  const handleTextSizeChange = (value) => {
    setTextSize(value);
  };

  const handleTextColorChange = (value) => {
    setTextColor(value);
  };

  const handleBgChange = (bg) => {
    setBgChange(bg);
  };

  const author = (daily) => {
    let matchedAuthor = null;
    if (Array.isArray(authorName)) {
      matchedAuthor = authorName.find(
        (authorObj) => authorObj.author === daily.leader
      );
    }
    if (matchedAuthor) {
      return matchedAuthor.description;
    } else if (pendingAuthorName || !matchedAuthor) {
      return daily.author;
    }
  };

  return (
    <Container
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginTop: '2rem',
      }}
    >
      {pendingDailyQuote ? (
        <SpinnerContainer>
          <SpinnerQuotes />
        </SpinnerContainer>
      ) : (
        <Container
          sx={{
            width: '100%',
          }}
        >
          <Carousel
            showStatus={false}
            showThumbs={false}
            showIndicators={true}
            swipeable
            infiniteLoop
            onChange={(index) => setCurrentDailyQuote(dailyQuotes[index])}
            renderIndicator={(clickHandler, isSelected) => (
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: isSelected
                    ? theme.palette.text.secondary
                    : theme.palette.text.primary,
                  margin: '0 5px',
                  borderRadius: '50%',
                  display: 'inline-block',
                  cursor: 'pointer',
                }}
                onClick={clickHandler}
              />
            )}
          >
            {dailyQuotes.map((dailyQuote, index) => (
              <ImageContainer
                style={{
                  backgroundImage: `url(${
                    bgChange || (bgImages[index] && bgImages[index].url)
                  })`,
                }}
                key={`dailyQuote-${index}`}
              >
                <ImageTextContainer key={dailyQuote.id}>
                  {dailyQuote.descEn && dailyQuote.authorEn ? (
                    <QuoteContainer>
                      <EnglishQuoteText>{dailyQuote.descEn}</EnglishQuoteText>
                      <EnglishAuthorText>
                        -{dailyQuote.authorEn}
                      </EnglishAuthorText>
                    </QuoteContainer>
                  ) : null}
                  <QuoteContainer>
                    <QuoteText>{dailyQuote.desc}</QuoteText>
                    <AuthorText>-{author(dailyQuote)}</AuthorText>
                  </QuoteContainer>
                </ImageTextContainer>
              </ImageContainer>
            ))}
          </Carousel>

          <ToolsContainerMobile
            onTextSizeChange={handleTextSizeChange}
            onTextColorChange={handleTextColorChange}
            textColor={textColor}
            quoteData={currentDailyQuote}
            textSize={textSize}
            onBgChange={handleBgChange}
            bgChange={bgChange}
          />
        </Container>
      )}
    </Container>
  );
}
