import { useEffect, useState } from 'react';
import axiosQuotes from '../../../api/axiosQuotes';

const useImageQuotes = (image_code) => {
  const [imageQuotes, setImageQuotes] = useState([]);
  const [pendingImageQuotes, setpendingImageQuotes] = useState(true);
  const [refresh, setRefresh] = useState(false);

  // https://tamil-quotes-api.smartdroidies.com/imagequote?event_code=fathers_day

  const loadImageQuotes = (image_code) => {
    axiosQuotes
      .get('/imagequote', { params: { event_code: image_code.image_code } })
      .then((response) => {
        setImageQuotes(response.data);
        setpendingImageQuotes(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    loadImageQuotes(image_code);
  }, [refresh, image_code]);

  return [imageQuotes, pendingImageQuotes, refresh, setRefresh];
};

export default useImageQuotes;
