import * as React from 'react';
import Container from '@mui/material/Container';
import CategoryList from '../features/category/components/CategoryList';

export default function Home() {
  return (
    <Container sx={{ marginTop: '1rem' }}>
      <CategoryList />
    </Container>
  );
}
