const localQuotes = [
  'பொறுமை உயர்ந்த பிரதியேக அளவு உண்டு.',
  'பொறுமையும் பலமும் பண்ணுவது முக்கியம்.',
  'பொறுமை உள்ளவர் பெயர்ச்சி அகப்படுகிறது.',
  'கனவுகளை முறையாக ஒழுங்கின்றால் பொறுமை பெருகும்.',
  'பொறுமைக்குப் பின் இரகசியம் என்பது திருப்தி தரும்.',
  'இருப்பவர்கள் உடனுக்குப் பொறுமை விரும்பும்.',
  'பொறுமைக்கு கவனம் கொடுக்க வேண்டிய காரியம் தனியும் உறுதியும் இருக்க வேண்டும்.',
];

export default localQuotes;
