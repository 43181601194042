import { HelmetProvider } from 'react-helmet-async';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import DailyQuotesScreen from '../src/pages/DailyQuoteScreen';
import './App.css';
import Layout from './components/Layout';
import Event from './features/events/components/Event';
import CategoryScreen from './pages/CategoryScreen';
import EventsScreen from './pages/EventsScreen';
import Home from './pages/Home';
import NoPage from './pages/NoPage';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/events" element={<EventsScreen />} />
            <Route path="/dailyquotes" element={<DailyQuotesScreen />} />
            <Route path="/event/:event_id/:image_code?" element={<Event />} />
            <Route
              path="/category_quote/:category_code/:encoded_description?"
              element={<CategoryScreen />}
            />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
