import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  useMediaQuery,
  Container,
  Typography,
} from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import SpinnerQuotes from '../../../components/SpinnerQuotes';
import { useNavigate } from 'react-router-dom';
import useEvents from '../hooks/useEvents';
import { theme } from '../../../themes/Theme';
import { SpinnerContainer } from '../../../styles/Comstyles';
import ShareIcon from '@mui/icons-material/Share';
import { styled } from '@mui/system';

const Share = styled('div')(({ theme }) => ({
  float: 'right',
  margin: '1rem',
  backgroundColor: theme.palette.background.category,
  borderRadius: '20px',
}));

const StyledShareIcon = styled(ShareIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: '8px',
  cursor: 'pointer',
}));

export default function EventsList() {
  const navigate = useNavigate();
  const [events, pendingEvents] = useEvents([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onEventSelect = (paramEvent) => {
    let path = '/event/' + paramEvent.id;
    if (paramEvent.image_code) {
      path += '/' + paramEvent.image_code;
    }
    navigate(path);
  };

  const handleShareEvent = async (ButtonName, paramEvent) => {
    let path = '/event/' + paramEvent.id;
    if (paramEvent.image_code) {
      path += '/' + paramEvent.image_code;
    }
    try {
      await navigator.share({
        url: path,
        title: paramEvent.event_title,
      });
      if (window.gtag) {
        window.gtag('event', 'Button_click', {
          event_category: 'Button Clicks',
          event_label: ButtonName,
        });
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    const date = new Date(year, month - 1, day);
    const formattedDate = date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
    return formattedDate;
  };

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.default',
        marginTop: '1rem',
      }}
    >
      {pendingEvents ? (
        <SpinnerContainer>
          <SpinnerQuotes />
        </SpinnerContainer>
      ) : (
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {events.length === 0 ? (
            <Typography>No Upcoming Events Right now</Typography>
          ) : (
            events
              .sort((a, b) => new Date(b.event_date) - new Date(a.event_date))
              .map((event) => (
                <Card
                  key={event.id}
                  sx={{
                    marginBottom: '1rem',
                    width: isMobile ? '100%' : '60%',
                  }}
                >
                  <CardActionArea onClick={() => onEventSelect(event)}>
                    <CardMedia
                      component="img"
                      src={event.image}
                      alt={event.event_title}
                    />
                    <Share>
                      <StyledShareIcon
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent card click
                          handleShareEvent('ShareEvent', event);
                        }}
                      />
                    </Share>
                    <CardContent
                      sx={{ bgcolor: theme.palette.background.card }}
                    >
                      <Typography variant="h5" component="div">
                        {event.event_title}
                      </Typography>
                      <Typography>{formatDate(event.event_date)}</Typography>
                      <Typography variant="body2">{event.desc}</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))
          )}
        </Container>
      )}
    </Container>
  );
}
