import { useEffect, useState } from 'react';
import axiosQuotes from '../../../api/axiosQuotes';

const useEvents = () => {
  const [events, setEvents] = useState([]);
  const [pendingEvents, setpendingEvents] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 10); // Subtract 10 days
  const formattedDate = currentDate.toISOString().split('T')[0];

  // https://tamil-quotes-api.smartdroidies.com/daily?curr_date=2023-05-16
  const loadEvents = (formattedDate) => {
    axiosQuotes
      .get('/events', { params: { curr_date: formattedDate } })
      .then((response) => {
        setEvents(response.data);
        setpendingEvents(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    loadEvents(formattedDate);
  }, [formattedDate]);

  return [events, pendingEvents, refresh, setRefresh];
};

export default useEvents;
