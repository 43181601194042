import { useEffect, useState } from 'react';
import axiosQuotes from '../../../api/axiosQuotes';

const useBgImages = (bgImageCode) => {
  const [bgImages, setBgImages] = useState([]);
  const [pendingBgImages, setpendingBgImages] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const loadAuthorNames = (bgImageCode) => {
    axiosQuotes
      .get('/bgimages', { params: { code: bgImageCode } })
      .then((response) => {
        setBgImages(response.data);
        setpendingBgImages(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    loadAuthorNames(bgImageCode);
  }, [refresh, bgImageCode]);

  return [bgImages, pendingBgImages, refresh, setRefresh];
};

export default useBgImages;
