import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SpinnerQuotes from '../../../components/SpinnerQuotes';
import { SpinnerContainer } from '../../../styles/Comstyles';
import { theme } from '../../../themes/Theme';
import useCategoryList from '../hooks/UseCategoryList';
import useCategoryTitle from '../hooks/UseCategoryTitle';

const GroupTitle = styled('div')(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.5rem',
  margin: '1rem 0 1rem 0',
}));

export default function CategoryList() {
  const navigate = useNavigate();
  const [categoryList, pendingCategoryList] = useCategoryList();
  const [categoryTitle] = useCategoryTitle();
  const sortedCategoryList = categoryList.sort((a, b) => a.weight - b.weight);
  const groups = [
    ...new Set(sortedCategoryList.map((category) => category.group)),
  ];

  const onCategorySelect = (paramCategory) => {
    let encodedDescription = encodeURIComponent(paramCategory.description);
    let path = '/category_quote/' + paramCategory.code;
    if (encodedDescription) {
      path += '/' + encodedDescription;
    }
    navigate(path);
  };

  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'background.default',
        padding: '10px',
      }}
    >
      {pendingCategoryList ? (
        <SpinnerContainer>
          <SpinnerQuotes />
        </SpinnerContainer>
      ) : (
        <div>
          {groups.map((group) => {
            const groupTitle = categoryTitle.ctgry_groups
              ? categoryTitle.ctgry_groups.find((title) => title.code === group)
              : null;

            return (
              <div key={group}>
                <GroupTitle>{groupTitle ? groupTitle.desc : group}</GroupTitle>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  {sortedCategoryList
                    .filter((category) => category.group === group)
                    .map((category) => (
                      <Grid key={category.code} item xs={6} md={3}>
                        <Card
                          sx={{
                            backgroundColor: theme.palette.background.category,
                            color: theme.palette.text.secondary,
                            height: '100px',
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'center',
                          }}
                          onClick={() => onCategorySelect(category)}
                        >
                          <CardActionArea>
                            <CardContent>
                              <Typography sx={{ fontWeight: '700' }}>
                                {category.description}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))}
                </Grid>
              </div>
            );
          })}
        </div>
      )}
    </Container>
  );
}
