import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { Link } from '@mui/material';
import BottomNavigation from './BottomNavigation';
import { theme } from '../themes/Theme';
import { useMediaQuery } from '@mui/material';

const FooterContainer = styled('footer')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: theme.palette.background.footer,
  color: theme.palette.text.primary,
  padding: '0.5rem',
  zIndex: 1,
  textAlign: 'center',
}));

const FooterColumns = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
  alignItems: 'center',
  justifyContent: 'center',
}));

const CopyRightHeading = styled('div')(({ theme }) => ({
  fontWeight: 'bold',
  margin: '0.5rem',
  fontSize: '0.8rem',
  color: theme.palette.text.primary,
}));

const StyledListItemIcon = styled('div')(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },
}));

const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: '1rem',
});

const ContentContainer = styled('div')(({ theme }) => ({
  marginBottom: '10rem',
}));

function DevelopmentMode() {
  return (
    <Box>
      {process.env.REACT_APP_ENVIRONMENT === 'Development' && (
        <span style={{ fontSize: '0.8rem' }}>****** Dev Mode *****</span>
      )}
    </Box>
  );
}

function CopyRights() {
  return (
    <Box>
      <CopyRightHeading>CopyRights @2023 ponmozigal.com</CopyRightHeading>
    </Box>
  );
}

const handleButtonClick = (ButtonName) => {
  if (window.gtag) {
    window.gtag('event', 'Button_click', {
      event_category: 'Button Clicks',
      event_label: ButtonName,
    });
  }
};

const Footer = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ContentContainer>
      <FooterContainer>
        {!isMobile && (
          <>
            <FooterColumns>
              <div>
                <IconContainer>
                  <StyledListItemIcon>
                    <Link
                      href="mailto:smartdroides@gmail.com"
                      target="_blank"
                      rel="noopener"
                      onClick={handleButtonClick('Email')}
                    >
                      <EmailIcon />
                    </Link>
                  </StyledListItemIcon>
                  <StyledListItemIcon>
                    <Link
                      href="https://www.youtube.com/@ponmozigal"
                      target="_blank"
                      rel="noopener"
                      onClick={handleButtonClick('Youtube')}
                    >
                      <YouTubeIcon />
                    </Link>
                  </StyledListItemIcon>
                  <StyledListItemIcon>
                    <Link
                      href="https://www.facebook.com/Ponmozigal"
                      target="_blank"
                      rel="noopener"
                      onClick={handleButtonClick('facebook')}
                    >
                      <FacebookIcon />
                    </Link>
                  </StyledListItemIcon>
                  <StyledListItemIcon>
                    <Link
                      href="https://www.instagram.com/your-profile"
                      target="_blank"
                      rel="noopener"
                      onClick={handleButtonClick('insatgram')}
                    >
                      <InstagramIcon />
                    </Link>
                  </StyledListItemIcon>
                </IconContainer>
              </div>
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.smart.droid.thalaivargal&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  onClick={handleButtonClick('playstore')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    style={{ width: '100px' }}
                  />
                </a>
              </div>
            </FooterColumns>

            <CopyRights />
            <DevelopmentMode />
          </>
        )}
        <div style={{ flexGrow: 1 }}>
          <BottomNavigation />
        </div>
      </FooterContainer>
    </ContentContainer>
  );
};

export default Footer;
