import React from 'react';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { CardActionArea, Container, useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import SpinnerQuotes from '../../../components/SpinnerQuotes';
import Typography from '@mui/material/Typography';
import useEvent from '../hooks/useEvent';
import ImageQuotes from '../../image/components/ImageQuotes';
import { theme } from '../../../themes/Theme';
import { SpinnerContainer } from '../../../styles/Comstyles';
import { styled } from '@mui/system';
import Divider from '@mui/material/Divider';
import ShareIcon from '@mui/icons-material/Share';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';

const ContentTitles = styled('div')(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.5rem',
  marginBottom: '1.2rem',
  justifyContent: 'left',
}));

const ContentDivider = styled(Divider)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: '1.2rem',
  color: theme.palette.indicator.indicator,
  borderBottom: '3px solid',
}));

const Share = styled('div')(({ theme }) => ({
  float: 'right',
  margin: '1rem',
  backgroundColor: theme.palette.background.category,
  borderRadius: '20px',
}));

const StyledShareIcon = styled(ShareIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: '8px',
  cursor: 'pointer',
}));

export default function Event() {
  const navigate = useNavigate();
  const { event_id, image_code } = useParams();
  const [event, pendingEvent] = useEvent(event_id);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const videoUrls =
    event.videos && event.videos.length > 0
      ? event.videos.includes(',')
        ? event.videos.split(',')
        : [event.videos]
      : [];

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    const date = new Date(year, month - 1, day);
    const formattedDate = date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
    return formattedDate;
  };

  const handleShareEvent = async (ButtonName, paramEvent) => {
    let path = '/event/' + paramEvent.id;
    if (paramEvent.image_code) {
      path += '/' + paramEvent.image_code;
    }
    try {
      await navigator.share({
        url: path,
        title: paramEvent.event_title,
      });
      if (window.gtag) {
        window.gtag('event', 'Button_click', {
          event_category: 'Button Clicks',
          event_label: ButtonName,
        });
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const CategoryTag = (code, tag) => {
    let path = `/category_quote/${code}/${tag}`;
    navigate(path);
  };

  const tagTam = (tag) => {
    const cachedCategoryList = JSON.parse(localStorage.getItem('categoryList'));
    if (cachedCategoryList) {
      const foundCategory = cachedCategoryList.find(
        (category) => category.code === tag
      );
      if (foundCategory) {
        return foundCategory.description;
      }
    }
    return tag;
  };

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.default',
        marginTop: '1rem',
      }}
    >
      {pendingEvent ? (
        <SpinnerContainer>
          <SpinnerQuotes />
        </SpinnerContainer>
      ) : (
        <Container
          sx={{
            marginBottom: '1rem',
            width: isMobile ? '100%' : '60%',
          }}
        >
          <Card key={event}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt={event.event_title}
                src={event.image}
              />
              <Share>
                <StyledShareIcon
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent card click
                    handleShareEvent('ShareEvent', event);
                  }}
                />
              </Share>
              <CardContent sx={{ bgcolor: theme.palette.background.card }}>
                <Typography variant="h5" component="div">
                  {event.event_title}
                </Typography>
                <Typography>{formatDate(event.event_date)}</Typography>
                <Typography variant="body2">{event.desc}</Typography>
                <Stack
                  direction={isMobile ? 'column' : 'row'}
                  spacing={1}
                  sx={{
                    marginTop: '1rem',
                  }}
                >
                  {event.tags.split(',').map((tag, index) => (
                    <Chip
                      key={index}
                      label={tagTam(tag.trim())}
                      variant="outlined"
                      onClick={() =>
                        CategoryTag(tag.trim(), tagTam(tag.trim()))
                      }
                      sx={{
                        backgroundColor: theme.palette.text.hover,
                        color: theme.palette.text.secondary,
                        fontWeight: 'bold',
                        marginBottom: isMobile ? '0.5rem' : '0',
                      }}
                    />
                  ))}
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
          {event.videos ? (
            <>
              <ContentDivider />
              <ContentTitles>இந்த நிகழ்வு தொடர்பான வீடியோக்கள் :</ContentTitles>
              <Carousel
                swipeable
                infiniteLoop
                showThumbs={false}
                showIndicators={true}
                renderIndicator={(clickHandler, isSelected) => (
                  <div
                    style={{
                      width: '10px',
                      height: '10px',
                      backgroundColor: isSelected
                        ? theme.palette.text.secondary
                        : theme.palette.indicator.indicator,
                      margin: '0 5px',
                      borderRadius: '50%',
                      display: 'inline-block',
                      cursor: 'pointer',
                    }}
                    onClick={clickHandler}
                  />
                )}
              >
                {videoUrls.map((videoUrl) => (
                  <div key={videoUrl}>
                    <iframe
                      height="400"
                      src={videoUrl}
                      title="YouTube video player"
                      allowFullScreen
                    ></iframe>
                  </div>
                ))}
              </Carousel>
            </>
          ) : null}
          {image_code ? (
            <>
              <ContentDivider />
              <ContentTitles>பட மேற்கோள்கள் :</ContentTitles>
              <ImageQuotes image_code={image_code} />
            </>
          ) : null}
        </Container>
      )}
    </Container>
  );
}
