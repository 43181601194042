import React from 'react';
import { useParams } from 'react-router-dom';
import CategoryQuote from '../features/category/components/CategoryQuote';
import { useMediaQuery } from '@mui/material';
import CategoryQuoteMobile from '../features/category/components/CategoryQuoteMobile';
import { theme } from '../themes/Theme';

const CategoryScreen = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { category_code, encoded_description } = useParams();
  return (
    <>
      {isMobile ? (
        <CategoryQuoteMobile
          category_Code={category_code}
          encoded_Description={encoded_description}
        />
      ) : (
        <CategoryQuote
          category_Code={category_code}
          encoded_Description={encoded_description}
        />
      )}
    </>
  );
};

export default CategoryScreen;
