import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import { ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { theme, darkMode } from '../themes/Theme';
import { styled } from '@mui/system';

const ColorModeContext = React.createContext({});

// eslint-disable-next-line no-unused-vars
const SwitchMode = () => {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  return (
    <IconButton sx={{ marginLeft: '1rem' }} onClick={colorMode.toggleColorMode}>
      {theme.palette.mode === 'dark' ? (
        <Brightness7Icon />
      ) : (
        <Brightness4Icon />
      )}
    </IconButton>
  );
};

const PageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

export default function Layout() {
  const [currentTheme, setTheme] = React.useState(theme);

  const toggleColorMode = () => {
    setTheme((prevTheme) =>
      prevTheme.palette.mode === 'light' ? darkMode : theme
    );
  };

  return (
    <ColorModeContext.Provider value={{ toggleColorMode }}>
      <ThemeProvider theme={currentTheme}>
        <PageContainer>
          <Header />
          <div
            style={{
              position: 'absolute',
              top: '0.7rem',
              right: '0.7rem',
              background: 'transparent',
            }}
          >
            {/* <SwitchMode /> */}
          </div>
          <Outlet />
          <Footer />
        </PageContainer>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
