import { useEffect, useState } from 'react';
import axiosQuotes from '../../../api/axiosQuotes';

const useCategoryQuote = (categoryCode) => {
  const [categoryQuote, setCategoryQuote] = useState([]);
  const [pendingCategoryQuote, setpendingCategoryQuote] = useState(true);
  const [refresh, setRefresh] = useState(false);

  //https://tamil-quotes-api.smartdroidies.com/userquote?code=hard_work%27

  const loadCategoryQuotes = (categoryCode) => {
    axiosQuotes
      .get('/userquote', { params: { code: categoryCode } })
      .then((response) => {
        setCategoryQuote(response.data);
        setpendingCategoryQuote(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    loadCategoryQuotes(categoryCode);
  }, [refresh, categoryCode]);

  return [categoryQuote, pendingCategoryQuote, refresh, setRefresh];
};

export default useCategoryQuote;
