import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const LogoHeading = styled(Typography)(({ theme }) => ({
  marginLeft: 10,
  display: { xs: 'flex', md: 'flex' },
  fontWeight: 700,
  color: theme.palette.text.primary,
}));

export const OnlyXsScreen = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.only('xs')]: {
    display: 'flex',
  },
}));

export const OnlyMdScreen = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.only('md')]: {
    display: 'flex',
    [theme.breakpoints.only('ml')]: {
      display: 'none',
    },
  },
}));

export const OnlyMlScreen = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.only('ml')]: {
    display: 'flex',
  },
}));

export const XsMdScreen = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.only('xs')]: {
    display: 'flex',
  },
  [theme.breakpoints.between('md')]: {
    display: 'flex',
  },
}));

export const MdMlScreen = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.between('md', 'ml')]: {
    display: 'flex',
  },
}));

export const XsMdMlScreen = styled('div')`
  display: flex;
`;

export const SpinnerContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
});
