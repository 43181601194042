import styled from '@emotion/styled';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import { React, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import SpinnerQuotes from '../../../components/SpinnerQuotes';
import { getRandomImage } from '../../../local/images';
import { SpinnerContainer } from '../../../styles/Comstyles';
import { theme } from '../../../themes/Theme';
import useAuthorName from '../../author/hooks/useAuthorName';
import useCategoryQuote from '../hooks/UseCategoryQuote';
import ToolsContainer from '../../../components/ToolsContainer';

const ImageContainer = styled('div')(({ theme }) => ({
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: '25rem',
  display: 'flex',
  alignItems: 'center',
}));

const ImageTextContainer = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  margin: '2rem',
  flexGrow: '1',
}));

const ContentTitles = styled('div')(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.5rem',
  marginBottom: '1.2rem',
  justifyContent: 'left',
}));

export default function CategoryQuote(props) {
  const { category_Code, encoded_Description } = props;
  const [categoryQuote, pendingCategoryQuote] = useCategoryQuote(category_Code);
  const [authorName, pendingauthorName] = useAuthorName([]);
  const decodedDescription = decodeURIComponent(encoded_Description);
  const [textSize, setTextSize] = useState(120);
  const [bgChange, setBgChange] = useState();
  const [textColor, setTextColor] = useState('#FFFFFF');
  const [currentCategoryQuote, setCurrentCategoryQuote] = useState(null);

  const EnglishQuoteText = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: `${textSize - 20}%`,
    color: textColor,
  }));

  const EnglishAuthorText = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: `${textSize - 30}%`,
    color: textColor,
  }));
  const QuoteText = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: `${textSize}%`,
    color: textColor,
  }));

  const AuthorText = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: `${textSize - 20}%`,
    color: textColor,
  }));

  const QuoteContainer = styled(Typography)(({ theme }) => ({
    margin: '3rem',
  }));

  const handleTextSizeChange = (value) => {
    setTextSize(value);
  };

  const handleTextColorChange = (value) => {
    setTextColor(value);
  };

  const handleBgChange = (bg) => {
    setBgChange(bg);
  };

  useEffect(() => {
    if (categoryQuote.length > 0) {
      setCurrentCategoryQuote(categoryQuote[0]);
    }
  }, [categoryQuote]);

  const author = (categoryAuthor) => {
    let matchedAuthor = null;
    if (Array.isArray(authorName)) {
      matchedAuthor = authorName.find(
        (authorObj) => authorObj.author === categoryAuthor.author
      );
    }
    if (matchedAuthor) {
      return matchedAuthor.description;
    } else if (pendingauthorName || !matchedAuthor) {
      return categoryAuthor.author;
    }
  };

  return (
    <Container
      sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
    >
      {pendingCategoryQuote ? (
        <SpinnerContainer>
          <SpinnerQuotes />
        </SpinnerContainer>
      ) : (
        <Container sx={{ width: '60%', margin: '1rem' }}>
          <ContentTitles>{decodedDescription || 'JSON'}</ContentTitles>
          <Carousel
            showThumbs={false}
            swipeable
            infiniteLoop
            showIndicators={false}
            onChange={(index) => setCurrentCategoryQuote(categoryQuote[index])}
            renderIndicator={(clickHandler, isSelected) => (
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: isSelected
                    ? theme.palette.text.secondary
                    : theme.palette.text.sideNavColor,
                  margin: '0 5px',
                  borderRadius: '50%',
                  display: 'inline-block',
                  cursor: 'pointer',
                }}
                onClick={clickHandler}
              />
            )}
          >
            {categoryQuote.map((quote) => (
              <ImageContainer
                key={quote.desc}
                style={{
                  backgroundImage: `url(${bgChange || getRandomImage()})`,
                }}
              >
                <ImageTextContainer>
                  {quote.descEn && quote.authorEn ? (
                    <QuoteContainer>
                      <EnglishQuoteText>{quote.descEn}</EnglishQuoteText>
                      <EnglishAuthorText>-{quote.authorEn}</EnglishAuthorText>
                    </QuoteContainer>
                  ) : null}
                  <QuoteContainer>
                    <QuoteText
                      component="div"
                      color={theme.palette.text.primary}
                    >
                      {quote.desc}
                    </QuoteText>
                    <AuthorText color={theme.palette.text.primary}>
                      -{author(quote)}
                    </AuthorText>
                  </QuoteContainer>
                </ImageTextContainer>
              </ImageContainer>
            ))}
          </Carousel>
          <ToolsContainer
            onTextSizeChange={handleTextSizeChange}
            onTextColorChange={handleTextColorChange}
            textColor={textColor}
            quoteData={currentCategoryQuote}
            textSize={textSize}
            onBgChange={handleBgChange}
            bgChange={bgChange}
          />
        </Container>
      )}
    </Container>
  );
}
