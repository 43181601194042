import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SendIcon from '@mui/icons-material/Send';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import Container from '@mui/material/Container';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import { HuePicker } from 'react-color';
import categoryImageArray from '../local/images';
import { theme } from '../themes/Theme';
import useAuthorName from '../features/author/hooks/useAuthorName';

const Tools = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  backgroundColor: theme.palette.background.tools,
});

const EditContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.background.tools,
});

// const ShareIconsContainer = styled('div')({
//   display: 'flex',
//   flexDirection: 'row',
//   alignItems: 'center',
//   justifyContent: 'center',
//   gap: '10px',
// });

const BarTools = styled('div')({
  display: 'flex',
  margin: '0.313rem',
  justifyContent: 'center',
  alignItems: 'center',
});

const ScrollableContainer = styled(Container)({
  overflowX: 'auto',
  width: '50%',
  height: '9.375rem',
  display: 'flex',
});

export default function ToolsContainer({
  quoteData,
  onTextSizeChange,
  onTextColorChange,
  onBgChange,
}) {
  const [textEditBarVisible, setTextEditBarVisible] = useState(false);
  const [textEditColorVisible, setTextEditColorVisible] = useState(false);
  const [bgImageGridVisible, setBgImageGridVisible] = useState(false);
  const [textSize, setTextSize] = useState(120);
  const [textColor, setTextColor] = useState('#FFFFFF');
  // eslint-disable-next-line no-unused-vars
  const [changeBg, setChangeBg] = useState();
  const [open, setOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [authorName, pendingAuthorName] = useAuthorName([]);
  const [activeIcon, setActiveIcon] = useState(null);

  //fetching Author
  const author = (auth) => {
    let matchedAuthor = null;
    if (Array.isArray(authorName)) {
      matchedAuthor = authorName.find((authorObj) => authorObj.author === auth);
    }
    if (matchedAuthor) {
      return matchedAuthor.description;
    } else if (pendingAuthorName || !matchedAuthor) {
      return auth;
    }
  };

  // Copy Action
  const copyToClipboard = () => {
    const quoteText = `${quoteData.desc}\n\t\t\t\t\t- ${author(
      quoteData.author
    )}`;
    var checkQuote = copy(quoteText);
    if (checkQuote) {
      setOpen(true);
    } else {
      setErrorOpen(true);
    }
  };

  //Toaster
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={3} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setErrorOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  //Share
  const shareContent = async () => {
    const quoteText = `${quoteData.desc}\n\t\t\t\t\t- ${author(
      quoteData.author
    )}`;
    try {
      await navigator.share({
        files: [quoteData.bg],
        text: quoteText,
        url: 'https://www.ponmozigal.com',
      });
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  // Show hide tools in toolbar container
  const showTextSlider = () => {
    setTextEditBarVisible(!textEditBarVisible);
    setTextEditColorVisible(false);
    setBgImageGridVisible(false);
  };

  const showColorPicker = () => {
    setTextEditColorVisible(!textEditColorVisible);
    setTextEditBarVisible(false);
    setBgImageGridVisible(false);
  };

  const showBgGrid = () => {
    setBgImageGridVisible(!bgImageGridVisible);
    setTextEditBarVisible(false);
    setTextEditColorVisible(false);
  };

  // option changes for dailyquotes Screen from toolbar
  const handleSliderChange = (event, value) => {
    setTextSize(value);
    onTextSizeChange(value);
  };

  const handleColorChange = (color) => {
    setTextColor(color.hex);
    onTextColorChange(color.hex);
  };

  const handleBgChange = (bgUrl) => {
    if (bgUrl) {
      setChangeBg(bgUrl);
      onBgChange(bgUrl);
    }
  };

  const handleIconClick = (iconName) => {
    setActiveIcon(iconName === activeIcon ? null : iconName);
  };

  // FaceBookShare
  // const handleFacebookShare = () => {
  //   // const quoteText = `${quoteData.desc}\n\t\t\t\t\t- ${author(
  //   //   quoteData.author
  //   // )}`;
  //   window.FB.ui({
  //     method: 'share',
  //     href: 'https://ponmozigal.com/dailyquotes',
  //   });
  // };

  //Bg changes from Toolbar Action
  function BgImageList() {
    return (
      <>
        {categoryImageArray < 0 ? (
          <CircularProgress />
        ) : (
          <ScrollableContainer>
            <ImageList cols={categoryImageArray.length}>
              {categoryImageArray.map((url, index) => (
                <ImageListItem key={index}>
                  <img
                    src={url}
                    alt="bgimages"
                    loading="lazy"
                    style={{
                      width: '8.5rem',
                      display: 'block',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleBgChange(url)}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </ScrollableContainer>
        )}
      </>
    );
  }

  return (
    <Tools>
      {(textEditBarVisible || textEditColorVisible || bgImageGridVisible) && (
        <EditContainer>
          <BarTools>
            {textEditBarVisible && (
              <Slider
                sx={{ width: '18.75rem' }}
                value={textSize}
                onChange={handleSliderChange}
                min={90}
                max={200}
                defaultValue={120}
                aria-label="Default"
                valueLabelDisplay="auto"
              />
            )}
          </BarTools>
          <BarTools>
            {textEditColorVisible && (
              <HuePicker
                sx={{ width: '18.75rem' }}
                color={textColor}
                onChange={handleColorChange}
              />
            )}
          </BarTools>
          <BarTools>{bgImageGridVisible && <BgImageList />}</BarTools>
        </EditContainer>
      )}
      <ButtonContainer>
        <Tooltip title="Copy Quote" placement="top">
          <IconButton onClick={copyToClipboard} aria-label="Copy Quote">
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
        <Snackbar
          open={open}
          autoHideDuration={1000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{
              width: '100%',
              backgroundColor: theme.palette.background.toaster,
            }}
            action={action}
          >
            Quote copied
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorOpen}
          autoHideDuration={1000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            sx={{ width: '100%' }}
            action={action}
          >
            Error While Copying
          </Alert>
        </Snackbar>

        <Tooltip title="Text Color" placement="top">
          <IconButton
            onClick={() => {
              handleIconClick('TextColor');
              showColorPicker();
            }}
            aria-label="Text Color"
            style={{
              backgroundColor:
                activeIcon === 'TextColor'
                  ? theme.palette.background.category
                  : 'transparent',
            }}
          >
            <FormatColorFillIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Text Size" placement="top">
          <IconButton
            aria-label="Text Size"
            onClick={() => {
              handleIconClick('TextSize');
              showTextSlider();
            }}
            style={{
              backgroundColor:
                activeIcon === 'TextSize'
                  ? theme.palette.background.category
                  : 'transparent',
            }}
          >
            <FormatSizeIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Change Background" placement="top">
          <IconButton
            aria-label="Change Background"
            onClick={() => {
              handleIconClick('ChangeBg');
              showBgGrid();
            }}
            style={{
              backgroundColor:
                activeIcon === 'ChangeBg'
                  ? theme.palette.background.category
                  : 'transparent',
            }}
          >
            <WallpaperIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Share Quote" placement="top">
          <IconButton onClick={shareContent} aria-label="Share Quote">
            <SendIcon />
          </IconButton>
        </Tooltip>
      </ButtonContainer>
      {/* <ShareIconsContainer>
        <Tooltip title="Share quote on facebook" placement="top">
          <IconButton aria-label="facebook share" onClick={handleFacebookShare}>
            <FacebookIcon />
          </IconButton>
        </Tooltip>
      </ShareIconsContainer> */}
    </Tools>
  );
}
