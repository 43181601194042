import SpinnerQuotes from '../../../components/SpinnerQuotes';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useImageQuotes from '../hooks/useImageQuotes';
import { SpinnerContainer } from '../../../styles/Comstyles';

export default function ImageQuotes(image_code) {
  const [imageQuotes, pendingImageQuotes] = useImageQuotes(image_code);

  const groupedImageQuotes = [];
  for (let i = 0; i < imageQuotes.length; i += 3) {
    groupedImageQuotes.push(imageQuotes.slice(i, i + 3));
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'background.default',
      }}
    >
      {pendingImageQuotes ? (
        <SpinnerContainer>
          <SpinnerQuotes />
        </SpinnerContainer>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            width: '100%',
            '@media (maxWidth: 800px)': {
              width: '200%',
            },
          }}
        >
          <Carousel
            showThumbs={false}
            swipeable
            showIndicators={false}
            infiniteLoop
          >
            {groupedImageQuotes.map((group, index) => (
              <div key={index} style={{ display: 'flex' }}>
                {group.map((imageQuote) => (
                  <div
                    key={imageQuote.id}
                    style={{ width: '33%', marginRight: '4px' }}
                  >
                    <img
                      src={imageQuote.url}
                      alt="imageQuotes"
                      loading="lazy"
                      style={{ width: '100%', height: 'auto' }}
                    />
                  </div>
                ))}
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
}
