import { useEffect, useState } from 'react';
import axiosQuotes from '../../../api/axiosQuotes';

const useAuthorName = () => {
  const [authorName, setAuthorName] = useState([]);
  const [pendingauthorName, setpendingauthorName] = useState(true);
  const [refresh, setRefresh] = useState(false);

  // https://tamil-quotes-api.smartdroidies.com/author

  const loadAuthorNames = () => {
    axiosQuotes
      .get('/author')
      .then((response) => {
        setAuthorName(response.data);
        setpendingauthorName(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    loadAuthorNames();
  }, [refresh]);

  return [authorName, pendingauthorName, refresh, setRefresh];
};

export default useAuthorName;
