import React, { useEffect, useState } from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CabinIcon from '@mui/icons-material/Cabin';
import { styled } from '@mui/system';
import { Link, useLocation } from 'react-router-dom';
import { OnlyXsScreen } from '../styles/Comstyles';
import Badge from '@mui/material/Badge';
import useEvents from '../features/events/hooks/useEvents';

const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-evenly',
  backgroundColor: theme.palette.background.footer,
}));

const activeClassName = 'active';

const ButtonContainer = styled(BottomNavigationAction)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.text.hover,
  },
}));

const BottomNavig = () => {
  const location = useLocation();
  const [events] = useEvents([]);
  const [visitedEventsPage, setVisitedEventsPage] = useState(false);

  useEffect(() => {
    if (location.pathname === '/events') {
      setVisitedEventsPage(true);
    }
  }, [location.pathname]);

  return (
    <OnlyXsScreen>
      <StyledBottomNavigation showLabels>
        <ButtonContainer
          label="Home"
          icon={<CabinIcon />}
          component={Link}
          to="/home"
          className={location.pathname === '/home' ? activeClassName : ''}
        />
        <ButtonContainer
          label="Events"
          icon={
            !visitedEventsPage && events.length > 0 ? (
              <Badge badgeContent={events.length} color="error">
                <EventNoteIcon />
              </Badge>
            ) : (
              <EventNoteIcon />
            )
          }
          component={Link}
          to="/events"
          className={location.pathname === '/events' ? activeClassName : ''}
        />
        <ButtonContainer
          label="Today Quote"
          icon={<AccessTimeFilledIcon />}
          component={Link}
          to="/dailyquotes"
          className={
            location.pathname === '/dailyquotes' ? activeClassName : ''
          }
        />
      </StyledBottomNavigation>
    </OnlyXsScreen>
  );
};

export default BottomNavig;
