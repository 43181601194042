import { useEffect, useState } from 'react';
import axiosQuotes from '../../../api/axiosQuotes';

const useDailyQuotes = () => {
  const [dailyQuotes, setDailyQuotes] = useState([]);
  const [pendingQuote, setpendingDailyQuote] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split('T')[0];

  // '2023-06-29'

  const loadDailyQuotes = (formattedDate) => {
    axiosQuotes
      .get('/daily', { params: { curr_date: formattedDate } })
      .then((response) => {
        setDailyQuotes(response.data);
        setpendingDailyQuote(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    loadDailyQuotes(formattedDate);
  }, [refresh, formattedDate]);

  return [dailyQuotes, pendingQuote, refresh, setRefresh];
};

export default useDailyQuotes;
