import { useEffect, useState } from 'react';
import axiosQuotes from '../../../api/axiosQuotes';

const useEvent = (event_Id) => {
  const [event, setEvent] = useState([]);
  const [pendingEvent, setpendingEvent] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const loadEvent = (event_Id) => {
    axiosQuotes
      .get('/events', { params: { event_id: event_Id } })
      .then((response) => {
        setEvent(response.data);
        setpendingEvent(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    loadEvent(event_Id);
  }, [refresh, event_Id]);

  return [event, pendingEvent, refresh, setRefresh];
};

export default useEvent;
