import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { Toolbar, Typography, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { LogoHeading, OnlyMdScreen, XsMdMlScreen } from '../styles/Comstyles';
import { theme } from '../themes/Theme';
import SideNavigation from './SideNavigation';
import TopNavigation from './TopNavigation';

const handleButtonClick = (ButtonName) => {
  if (window.gtag) {
    window.gtag('event', 'Button_click', {
      event_category: 'Button Clicks',
      event_label: ButtonName,
    });
  }
};

function Header() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>பொன்மொழிகள்</title>
        <link rel="canonical" href="https://ponmozigal.com" />
        <meta
          property="og:title"
          content="Motivation and Inspiration quotes in Tamil"
        />
        <meta
          property="og:image"
          content="https://ponmozigal.com/logo192.png"
        />
        <meta property="og:helmet" content="true" />
        <meta property="og:react" content="true" />
      </Helmet>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <OnlyMdScreen>
              <SideNavigation />
            </OnlyMdScreen>
            <XsMdMlScreen sx={{ flexGrow: 1 }}>
              <Typography
                component={Link}
                to="/home"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                <FormatQuoteIcon />
                <LogoHeading>பொன்மொழிகள்</LogoHeading>
              </Typography>
              <TopNavigation />
              {isMobile && (
                <div>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.smart.droid.thalaivargal&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    onClick={handleButtonClick('playstore')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="Get it on Google Play"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      style={{ width: '130px' }}
                    />
                  </a>
                </div>
              )}
            </XsMdMlScreen>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default Header;
