import { useEffect, useState } from 'react';
import axiosQuotes from '../../../api/axiosQuotes';

const useCategoryList = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [pendingCategoryList, setPendingCategoryList] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const loadCategoryList = () => {
    const cachedCategoryList = localStorage.getItem('categoryList');
    const cachedTimestamp = localStorage.getItem('categoryListTimestamp');

    if (cachedCategoryList && cachedTimestamp) {
      const currentTime = new Date().getTime();
      const timeSinceCache = currentTime - parseInt(cachedTimestamp, 10);
      const cacheExpirationTime = 60 * 60 * 1000; //(min*sec*millisecond)
      if (timeSinceCache < cacheExpirationTime) {
        setCategoryList(JSON.parse(cachedCategoryList));
        setPendingCategoryList(false);
        return;
      }
    }
    axiosQuotes
      .get('/category')
      .then((response) => {
        setCategoryList(response.data);
        setPendingCategoryList(false);
        localStorage.setItem('categoryList', JSON.stringify(response.data));
        localStorage.setItem('categoryListTimestamp', new Date().getTime());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    loadCategoryList();
  }, [refresh]);

  return [categoryList, pendingCategoryList, refresh, setRefresh];
};

export default useCategoryList;
