import { useEffect, useState } from 'react';
import axiosQuotes from '../../../api/axiosQuotes';

const useCategoryTitle = () => {
  const [categoryTitle, setCategoryTitle] = useState([]);
  const [pendingCategoryTitle, setpendingCategoryTitle] = useState(true);
  const [refresh, setRefresh] = useState(false);

  // https://tamil-quotes-api.smartdroidies.com/reference?code=category_groups%27

  const loadCategoryTitles = () => {
    axiosQuotes
      .get('/reference', { params: { code: 'category_groups' } })
      .then((response) => {
        setCategoryTitle(response.data);
        setpendingCategoryTitle(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    loadCategoryTitles();
  }, [refresh]);

  return [categoryTitle, pendingCategoryTitle, refresh, setRefresh];
};

export default useCategoryTitle;
