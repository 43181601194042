import React, { useState, useEffect } from 'react';
import localQuotes from '../local/localQuotes';
import { Container } from '@mui/material';
import Grow from '@mui/material/Grow';
import { theme } from '../themes/Theme';

const SpinnerQuotes = () => {
  const [randomIndex, setRandomIndex] = useState(0);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const newIndex = Math.floor(Math.random() * localQuotes.length);
    setRandomIndex(newIndex);
    setChecked(true);
  }, []);

  return (
    <Container>
      <Grow in={checked}>
        <div
          style={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: theme.palette.text.teal,
          }}
        >
          {localQuotes[randomIndex]}
        </div>
      </Grow>
    </Container>
  );
};

export default SpinnerQuotes;
