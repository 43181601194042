import axios from 'axios';
import log from 'loglevel';
const axiosQuotes = axios.create({
  baseURL: '/quotes-api',
});

axiosQuotes.interceptors.request.use(async (request) => {
  // const currentSession = await Auth.currentSession();
  request.headers.Authorization = process.env.REACT_APP_AUTH_KEY;
  log.setLevel('trace');
  log.setLevel('debug');
  // log.trace(request.baseURL, request.url);  // tracing of program flow. It provides extensive information, often including variable values and function call details.
  // log.debug('nw request : ', request.baseURL, request.url);
  return request;
});

// axiosEvents.interceptors.response.use(
//   (response) => {
//     // console.log("Response : ", response);
//     return response;
//   },
//   (error) => {
//     console.log(error);
//   }
// );

export default axiosQuotes;
