import React from 'react';
import DailyQuotes from '../../src/features/daily/components/DailyQuotes';
import DailyQuotesMobile from '../features/daily/components/DailyQuotesMobile';
import { useMediaQuery } from '@mui/material';
import { theme } from '../themes/Theme';

const DailyQuotesScreen = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return <>{isMobile ? <DailyQuotesMobile /> : <DailyQuotes />}</>;
};

export default DailyQuotesScreen;
