import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery, Toolbar, Button, Badge } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import useEvents from '../features/events/hooks/useEvents';

const ButtonContainer = styled(Button)(({ theme }) => ({
  margin: '1rem',
  fontWeight: 'bold',
  color: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.text.hover,
  },
}));

function TopNavigation() {
  const mlScreen = useMediaQuery('(min-width:960px)');
  const [events] = useEvents([]);
  const location = useLocation();
  const [visitedEventsPage, setVisitedEventsPage] = useState(false);

  // Set visitedEventsPage when the component mounts
  useEffect(() => {
    if (location.pathname === '/events') {
      setVisitedEventsPage(true);
    }
  }, [location.pathname]);

  return (
    <Toolbar sx={{ flexGrow: 1, justifyContent: 'center' }}>
      {mlScreen && (
        <Box>
          <ButtonContainer key="home" component={NavLink} to="/home">
            Home
          </ButtonContainer>
          <ButtonContainer key="events" component={NavLink} to="/events">
            {!visitedEventsPage && events.length > 0 ? (
              <Badge badgeContent={events.length} color="error">
                Events
              </Badge>
            ) : (
              'Events'
            )}
          </ButtonContainer>
          <ButtonContainer
            key="dailyquotes"
            component={NavLink}
            to="/dailyquotes"
          >
            DailyQuotes
          </ButtonContainer>
        </Box>
      )}
    </Toolbar>
  );
}

export default TopNavigation;
