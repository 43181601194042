import { createTheme } from '@mui/material/styles';
import { purple, red, yellow, teal } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: teal,
    secondary: yellow,
    background: {
      default: '#ffffff',
      card: teal[500],
      footer: teal[500],
      tools: '#66cdaa',
      toaster: '#44d7a8',
      category: '#40e0d0',
    },
    text: {
      primary: '#ffffff',
      secondary: '#000000',
      sideNavColor: teal[500],
      hover: '#7fffd4',
      teal: teal[500],
    },
    indicator: {
      indicator: teal[500],
    },
  },
});

const darkMode = createTheme({
  palette: {
    mode: 'dark',
    primary: purple,
    secondary: red,
    background: {
      default: '#222222',
      color: '#222222', // Dark mode background color
    },
    text: {
      primary: '#ffffff', // Dark mode text color
      secondary: 'red',
    },
  },
});

export { theme, darkMode };
